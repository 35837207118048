import { createReducer } from "typesafe-actions";
import * as actions from "./workspaces.actions";
import {
  Project,
  ProjectResponse,
  ProjectResponseWithRole,
  WorkspaceDetails,
} from "./types";
import { AjaxError } from "rxjs/ajax";

import { logout } from "../user/user.actions";
import { resetState } from "../root.action";
import { DashboardOption } from "./DashboardOption";
import { defaultRoles } from "./defaultRoles";
import { Localized } from "../../strings";
import { isElectronMac } from "../../utils/is.electron.mac";
import { CreateWorkspaceFromTemplateParameters } from "./workspaces.actions";
import { Map as ImmutableMap } from "immutable";
import type { AbsolutePosition, Point } from "@hoylu/client-common";

/**
 *  IMPORTANT: When changing the schema version here also update it to the same value in /io-document/src/constants.ts
 */
export const DEFAULT_SCHEMA_VERSION = 3;

export enum ProjectStatuses {
  Active = "Active",
  Inactive = "Inactive",
  Expired = "Expired",
}

export type StoredSearchParams = { [key: string]: string };

export interface WorkspacesState {
  activeOption: DashboardOption | null; // TODO: This should be refactored to contain the entire payload for the modal as well (which is currently spread around the entire WorkspaceState)
  fetchFailure: boolean;
  creationParameters?: { useCaseName: string };
  waitingToEditID: string | null;
  waitingToEditName: string | null;
  waitingToEditAllowTemplateEdit?: boolean;
  waitingToCreateFromTemplate?: CreateWorkspaceFromTemplateParameters; // TODO: This should really be part of the modal state, not the entire workspace state (as so many other model states in here as well)
  selectedWorkspaceID: string | null;
  existing: WorkspaceDetails[];
  hasPopulatedAllWorkspaces: boolean;
  isLoadingAll: boolean;
  requestedUpdates: WorkspaceDetails[];
  defaultWorkspaceName: string;
  /** Used by sharing dialog to show spinner and to show invite users component */
  isUpdatingWorkspace: boolean;
  isEditingWorkspacePassword: boolean;
  modalTitle?: string;
  modalText?: string;
  modalButtonText?: string;
  modalButtonLinkUrl?: string;
  loadProgress?: number;
  loadState?: number;
  isCreatingNewWorkspaceUrl?: boolean | null;
  emailPreset?: string;
  cursorOrOffset: string | number; // TODO: this should be merged with hasPopulatedAllWorkspaces into one object
  assignableProjects?: ProjectResponse[];
  projects?: Map<string, ProjectResponseWithRole>;
  selectedProject: Project | null;
  projectCollaborators: string[];
  selectedProjectWorkspaces?: WorkspaceDetails[];
  isLoadingSelectedProjectWorkspaces: boolean;
  isEditingThumbnail: boolean;
  searchTerm: string;
  thumbnailsModified: ImmutableMap<string, Blob | null>; //Workaround for abusing augment workspace details refreshment
  tonnageUsage: number;
  showOverlay?: boolean;
  showHiddenWorkspaces: boolean;
  storedSearchParams: StoredSearchParams;
  waitingToLoadProjectID?: string;
  dialogPosition?: Point | AbsolutePosition;
}

export const defaultState = (): WorkspacesState => ({
  activeOption: null,
  fetchFailure: false,
  waitingToEditID: null,
  waitingToEditName: null,
  selectedWorkspaceID: null,
  existing: [],
  hasPopulatedAllWorkspaces: false,
  isLoadingAll: false,
  requestedUpdates: [],
  defaultWorkspaceName: "",
  isUpdatingWorkspace: false,
  isEditingWorkspacePassword: false,
  loadProgress: 0,
  selectedProject: null,
  projectCollaborators: [],
  loadState: 0,
  isCreatingNewWorkspaceUrl: null,
  cursorOrOffset: "",
  isLoadingSelectedProjectWorkspaces: false,
  isEditingThumbnail: false,
  searchTerm: "",
  tonnageUsage: 0,
  thumbnailsModified: ImmutableMap<string, Blob | null>(),
  showHiddenWorkspaces: false,
  storedSearchParams: {},
  waitingToLoadProjectID: undefined,
  dialogPosition: undefined
});

function updateDetailsForWorkspace<T extends WorkspaceDetails[] | undefined>(
  workspaces: T,
  updatedProps: Partial<WorkspaceDetails>
): T {
  // Note on the generic usage: This function should return undefined, if `workspaces` is undefined.
  //                            But also always a defined value if `workspaces` is defined. The generic is telling this to Typescript ;)
  if (!workspaces) return workspaces;
  return workspaces.map((w) =>
    w.workspaceId === updatedProps.workspaceId ? { ...w, ...updatedProps } : w
  ) as T;
}

function createWorkspaceName(state: any) {
  let currentDefaultWorkspaceName = state.defaultWorkspaceName || "Workspace 1";
  const existingWorkspaceNames = [...state.existing].map(
    (w) => w.workspaceName
  );
  while (existingWorkspaceNames.indexOf(currentDefaultWorkspaceName) >= 0) {
    const oldDefaultNameArray = currentDefaultWorkspaceName.split(/[ ,]+/);
    const currentCount = parseInt(oldDefaultNameArray[1], 10) + 1;
    currentDefaultWorkspaceName = "Workspace " + currentCount;
  }
  return currentDefaultWorkspaceName;
}

const sortByLastAccess:
  | ((a: WorkspaceDetails, b: WorkspaceDetails) => number)
  | undefined = (a, b) => {
  const aTime = +(a.lastAccess || Number.MIN_VALUE);
  const bTime = +(b.lastAccess || Number.MIN_VALUE);
  return bTime - aTime;
};

export function concatWorkspaces(
  current: WorkspaceDetails[],
  newWorkspaces: WorkspaceDetails[]
): WorkspaceDetails[] {
  let combined = current.concat(newWorkspaces);

  return combined.filter(
    (workspaceA, index, workspaceList) =>
      workspaceList.findIndex(
        (workspaceB) => workspaceB.workspaceId === workspaceA.workspaceId
      ) === index
  );
}
export function orderByLastAccess(
  workspaces: WorkspaceDetails[]
): WorkspaceDetails[] {
  const sorted = [...workspaces].sort(sortByLastAccess);
  const orderMap = sorted.reduce<{ [key: string]: number }>(
    (result, w, index) => ({ ...result, [w.workspaceId]: index }),
    {}
  );
  return workspaces.map((w) => ({ ...w, order: orderMap[w.workspaceId] }));
}

export function updateWorkspaces(
  current: WorkspaceDetails[] | undefined,
  updated: WorkspaceDetails[]
) {
  if (!current) return updated;

  // merge information in current that is not defined in updated workspaces
  // e.g.: to keep isLive, isFullscreen properties

  const result = updated.map((w) => {
    const old = current.find((cw) => cw.workspaceId === w.workspaceId);
    if (!old) return w;
    return { ...old, ...w };
  });

  // also make sure that whatever workspace in current that has isFullscreen is preserved (to not close a fullscreen workspace)
  const fullscreenWorkspace = current?.find((w) => w.isFullscreen);
  if (
    fullscreenWorkspace &&
    !result.find((w) => w.workspaceId === fullscreenWorkspace.workspaceId)
  ) {
    result.push(fullscreenWorkspace);
  }

  return result;
}

export default createReducer<WorkspacesState>(defaultState())
  .handleAction(resetState, () => defaultState())
  .handleAction([logout.success, logout.failure], () => defaultState())
  .handleAction(actions.queueEditWorkspace, (state, action) => {
    /* Temporary workaround to handle issue with endless loading on the Dashboard while opening a hidden Workspace.
       Hidden workspace, which was already loaded to the state.existing[] array is considered as ready to be opened.
       However, when the "hidden" section is closed, the proper WorkspaceCard is missing in the document and endless loading appears.
       Therefore, we open "hidden" section before trigering iFrame to start.
    */
    const isLoadedWorkspaceHidden = !!state.existing.find(
      (ws) => ws.workspaceId === action.payload.workspaceId && ws.isHidden
    );

    return {
      ...state,
      waitingToEditID: action.payload.workspaceId,
      waitingToEditAllowTemplateEdit: action.payload.allowEditTemplates,
      showHiddenWorkspaces:
        isLoadedWorkspaceHidden || state.showHiddenWorkspaces,
      selectedWorkspaceID: null,
      activeOption: null,
    };
  })
  .handleAction(actions.searchParamsForEditWorkspace, (state, action) => ({
    ...state,
    storedSearchParams: action.payload,
  }))
  .handleAction(actions.queueProjectLoading, (state, action) => ({
    ...state,
    waitingToLoadProjectID: action.payload,
  }))
  .handleAction(actions.queueCreateWorkspace, (state) => ({
    ...state,
    isCreatingNewWorkspaceUrl: true,
  }))
  .handleAction(actions.toggleHiddenWorkspaces, (state) => ({
    ...state,
    showHiddenWorkspaces: !state.showHiddenWorkspaces,
  }))
  .handleAction(actions.upsertWorkspaceUsers.request, (state) => ({
    ...state,
    isUpdatingWorkspace: true,
  }))
  .handleAction(actions.upsertWorkspaceUsers.failure, (state) => ({
    ...state,
    isUpdatingWorkspace: false,
  }))
  // upsertWorkspaceUsers.success is ignored, but should eventually be replacing once updateWorkspaceDetails.success isn't called anymore
  .handleAction(actions.getProjectCollaborators.success, (state, action) => ({
    ...state,
    projectCollaborators: action.payload,
  }))
  // getProjectCollaborators.request and getProjectCollaborators.failure are ignored as we do not need loading and e handling for now
  .handleAction(actions.removeWorkspaceUsers.request, (state) => ({
    ...state,
    isUpdatingWorkspace: true,
  }))
  .handleAction(actions.removeWorkspaceUsers.failure, (state) => ({
    ...state,
    isUpdatingWorkspace: false,
  }))
  // removeWorkspaceUsers.success is ignored, but should eventually be replacing once updateWorkspaceDetails.success isn't called anymore
  .handleAction(actions.updateWorkspaceName.request, (state) => ({
    ...state,
    isUpdatingWorkspace: true,
  }))
  .handleAction(actions.updateWorkspaceName.failure, (state) => ({
    ...state,
    isUpdatingWorkspace: false,
  }))
  // updateWorkspaceName.success is ignored, but should eventually be replacing once updateWorkspaceDetails.success isn't called anymore
  .handleAction(actions.updateWorkspaceDetails.request, (state, action) => {
    // Note: Eventially we not will use updateWorkspaceDetails.request anymore, but use the actions specific to a sub-property to change.
    let requestedUpdates = [...state.requestedUpdates, action.payload];
    return {
      ...state,
      requestedUpdates: requestedUpdates,
      isUpdatingWorkspace: requestedUpdates.length > 0,
    };
  })
  .handleAction(actions.updateWorkspaceDetails.success, (state, action) => {
    let requestedUpdates = state.requestedUpdates.filter(
      (w) => w.workspaceId !== action.payload.workspaceId
    );
    return {
      ...state,
      existing: updateDetailsForWorkspace(state.existing, action.payload),
      selectedProjectWorkspaces: updateDetailsForWorkspace(
        state.selectedProjectWorkspaces,
        action.payload
      ),
      requestedUpdates: requestedUpdates,
      isUpdatingWorkspace: requestedUpdates.length > 0,
    };
  })
  .handleAction(actions.updateWorkspaceDetails.failure, (state, action) => {
    let requestedUpdates = state.requestedUpdates.filter(
      (w) => w.workspaceId !== action.payload.requestAction.payload.workspaceId
    );
    return {
      ...state,
      requestedUpdates: requestedUpdates,
      isUpdatingWorkspace: requestedUpdates.length > 0,
      activeOption: null,
    };
  })
  .handleAction(actions.cancelDashboardOption, (state) => ({
    ...state,
    selectedWorkspaceID: null,
    activeOption: null,
    isEditingWorkspacePassword: false,
    isEditingThumbnail: false,
  }))
  .handleAction(actions.populateAllWorkspaces.request, (state) => ({
    ...state,
    fetchFailure: false,
    isLoadingAll: true,
  }))
  .handleAction(actions.populateAllWorkspaces.success, (state, action) => {
    const waitingToEditWorkspace = state.existing.find(
      (w) => w.workspaceId === state.waitingToEditID
    );
    const updatedExistingWorkspaces = updateWorkspaces(
      state.existing,
      action.payload.details
    );

    if (
      !updatedExistingWorkspaces.some(
        (w) => w.workspaceId === state.waitingToEditID
      ) &&
      waitingToEditWorkspace
    ) {
      updatedExistingWorkspaces.unshift(waitingToEditWorkspace);
    }

    return {
      ...state,
      existing: orderByLastAccess(updatedExistingWorkspaces),
      hasPopulatedAllWorkspaces: true,
      isLoadingAll: false,
    };
  })
  .handleAction(actions.populateAllWorkspaces.failure, (state) => ({
    ...state,
    fetchFailure: true,
    isLoadingAll: false,
  }))
  .handleAction(actions.populateInitialWorkspaces.request, (state) => ({
    ...state,
    fetchFailure: false,
  }))
  .handleAction(actions.populateInitialWorkspaces.success, (state, action) => {
    const waitingToEditWorkspace = state.existing.find(
      (w) => w.workspaceId === state.waitingToEditID
    );
    const updatedExistingWorkspaces = updateWorkspaces(
      state.existing,
      action.payload.details
    );

    if (
      !updatedExistingWorkspaces.some(
        (w) => w.workspaceId === state.waitingToEditID
      ) &&
      waitingToEditWorkspace
    ) {
      updatedExistingWorkspaces.unshift(waitingToEditWorkspace);
    }

    // TODO: Make DRYer
    const cursorOrOffset = action.payload.cursorOrOffset;
    const hasPopulatedAllWorkspaces =
      cursorOrOffset === "" || cursorOrOffset === null;
    return {
      ...state,
      existing: orderByLastAccess(updatedExistingWorkspaces),
      hasPopulatedAllWorkspaces,
      cursorOrOffset: hasPopulatedAllWorkspaces ? "" : cursorOrOffset,
    };
  })
  .handleAction(actions.populateInitialWorkspaces.failure, (state) => ({
    ...state,
    fetchFailure: true,
    hasPopulatedAllWorkspaces: false,
  }))
  .handleAction(actions.loadMoreWorkspaces.request, (state) => ({
    ...state,
    fetchFailure: false,
  }))
  .handleAction(actions.loadMoreWorkspaces.success, (state, action) => {
    const combined = concatWorkspaces(state.existing, action.payload.details);

    // TODO: Make DRYer
    const cursorOrOffset = action.payload.cursorOrOffset;
    const hasPopulatedAllWorkspaces =
      cursorOrOffset === "" || cursorOrOffset === null;
    return {
      ...state,
      existing: orderByLastAccess(combined),
      hasPopulatedAllWorkspaces,
      cursorOrOffset: hasPopulatedAllWorkspaces ? "" : cursorOrOffset,
    };
  })
  .handleAction(actions.loadMoreWorkspaces.failure, (state) => ({
    ...state,
    fetchFailure: true,
  }))
  .handleAction(actions.loadHiddenWorkspaces.success, (state, action) => {
    const combined = concatWorkspaces(state.existing, action.payload.details);

    return {
      ...state,
      existing: orderByLastAccess(combined),
    };
  })
  .handleAction(actions.getWorkspaceDetails.request, (state, action) => {
    const existing = [...state.existing];
    const updateIndex = existing.findIndex(
      (w) => w.workspaceId === action.payload
    );

    if (updateIndex === -1) {
      existing.unshift({
        workspaceId: action.payload,
        workspaceName: "",
        module: { name: "blank", configuration: {} },
        roles: defaultRoles(),
        labels: [],
      });
    }

    return {
      ...state,
      existing: orderByLastAccess(existing),
    };
  })
  .handleAction(actions.getWorkspaceDetails.success, (state, action) => {
    return {
      ...state,
      existing: updateDetailsForWorkspace(state.existing, {
        ...action.payload,
      }),
      selectedProjectWorkspaces: updateDetailsForWorkspace(
        state.selectedProjectWorkspaces,
        {
          ...action.payload,
        }
      ),
    };
  })
  .handleAction(actions.getWorkspaceDetails.failure, (state, action) => {
    let unauthorizedError = false;
    if (
      typeof action.payload.error !== "string" &&
      (action.payload.error as AjaxError).status === 401
    ) {
      unauthorizedError = true;
    }
    // close share dialog if there was one open
    const dialogState =
      state.activeOption === DashboardOption.SHARE ||
      state.activeOption === DashboardOption.PERMISSIONS ||
      state.activeOption === DashboardOption.INFO
        ? { activeOption: null, selectedWorkspaceID: null }
        : undefined;
    return {
      ...state,
      ...dialogState,
      waitingToEditID:
        state.waitingToEditID === action.payload.workspaceId &&
        !unauthorizedError
          ? null
          : state.waitingToEditID,
      existing: !unauthorizedError
        ? state.existing.filter(
            (w) => w.workspaceId !== action.payload.workspaceId
          )
        : state.existing,
    };
  })
  .handleAction(actions.atMaxWorkspaces, (state, action) => {
    return {
      ...state,
      waitingToEditID: null,
      existing: state.existing.filter((w) => w.workspaceId !== action.payload),
    };
  })
  .handleAction(actions.makeWorkspaceLive, (state, action) => ({
    ...state,
    existing: updateDetailsForWorkspace(state.existing, {
      workspaceId: action.payload,
      isLive: true,
    }),
  }))
  .handleAction(actions.setWorkspacePassword.success, (state, action) => ({
    ...state,
    existing: updateDetailsForWorkspace(state.existing, {
      workspaceId: action.payload.workspaceId,
      hasPassword: true,
    }),
  }))
  .handleAction(actions.removeWorkspacePassword.success, (state, action) => ({
    ...state,
    existing: updateDetailsForWorkspace(state.existing, {
      workspaceId: action.payload.workspaceId,
      hasPassword: false,
    }),
  }))
  .handleAction(actions.createWorkspaceFromTemplate, (state, action) => {
    // make sure waitingToEditID is set to null in case this action was executed through queueEditWorkspace
    return {
      ...state,
      waitingToEditID: null,
      waitingToEditName: null,
      waitingToCreateFromTemplate: action.payload,
    };
  })
  .handleAction(actions.editWorkspace, (state, action) => {
    const existing = [...state.existing];
    const workspace = existing.find(
      (w) => w.workspaceId === action.payload.workspaceId
    );
    if (!workspace) return state;
    return {
      ...state,
      loadProgress: 0,
      loadState: 0,
      waitingToEditID: null,
      waitingToEditName: workspace.workspaceName || null,
      isCreatingNewWorkspaceUrl: null,
      existing: orderByLastAccess(
        existing.map((w) =>
          w.workspaceId === action.payload.workspaceId
            ? {
                ...w,
                isLive: true,
                isFullscreen: true,
                lastAccess: action.payload.lastAccess || w.lastAccess,
                isFromMyWorkspaces: true,
              }
            : { ...w, isFullscreen: false }
        )
      ),
    };
  })
  .handleAction(actions.setLastAccess, (state, action) => {
    const existing = [...state.existing];
    return {
      ...state,
      existing: orderByLastAccess(
        existing.map((w) => ({
          ...w,
          lastAccess:
            (w.workspaceId === action.payload.workspaceId &&
              action.payload.lastAccess) ||
            w.lastAccess,
        }))
      ),
    };
  })
  .handleAction(actions.unfocusWorkspaces, (state) => ({
    ...state,
    waitingToEditID: null,
    waitingToEditName: null,
    isCreatingNewWorkspaceUrl: null,
    existing: state.existing.map((w) =>
      // for killing not fully loaded workspaces - avoid loading multiple in background
      w.isFullscreen && !w.isDocumentReady
        ? { ...w, isFullscreen: false, isLive: false, isChannelReady: false }
        : { ...w, isFullscreen: false }
    ),
  }))
  .handleAction(actions.createWorkspace.success, (state, action) => {
    let currentDefaultWorkspaceName =
      state.defaultWorkspaceName || "Workspace 1";
    if (action.payload.workspaceName === currentDefaultWorkspaceName) {
      const oldDefaultNameArray = currentDefaultWorkspaceName.split(/[ ,]+/);
      const currentCount = parseInt(oldDefaultNameArray[1], 10) + 1;
      currentDefaultWorkspaceName = "Workspace " + currentCount;
    }
    return {
      ...state,
      activeOption: null,
      existing: [{ ...action.payload }, ...state.existing],
      defaultWorkspaceName: currentDefaultWorkspaceName,
    };
  })
  .handleAction(actions.createWorkspace.failure, (state) => {
    return {
      ...state,
      activeOption: null,
      isCreatingNewWorkspaceUrl: null,
    };
  })
  .handleAction(actions.duplicateWorkspace.success, (state, action) => {
    return {
      ...state,
      activeOption: null,
      existing: [{ ...action.payload.details }, ...state.existing],
    };
  })
  .handleAction(actions.duplicateWorkspace.failure, (state, action) => {
    if (action.payload.clearError) {
      return {
        ...state,
        activeOption: null,
        selectedWorkspaceID: null,
        message: Localized.string("ERROR.CANNOT_DUPLICATE_WORKSPACE"),
      };
    } else {
      // Incorrect password submitted when trying to duplicate workspace
      return {
        ...state,
        activeOption: state.activeOption,
        selectedWorkspaceID: state.selectedWorkspaceID,
        message: Localized.string("ERROR.INCORRECT_PASSWORD"),
      };
    }
  })
  .handleAction(actions.workspaceChannelReady, (state, action) => ({
    ...state,
    existing: updateDetailsForWorkspace(state.existing, {
      workspaceId: action.payload,
      isChannelReady: true,
    }),
  }))
  .handleAction(actions.workspaceDocumentReady, (state, action) => ({
    ...state,
    existing: updateDetailsForWorkspace(state.existing, {
      workspaceId: action.payload,
      isDocumentReady: true,
    }),
  }))
  .handleAction(actions.toggleWorkspaceVisibility.request, (state) => {
    return {
      ...state,
      activeOption: null,
      selectedWorkspaceID: null,
    };
  })
  .handleAction(actions.toggleWorkspaceVisibility.success, (state, action) => ({
    ...state,
    activeOption: null,
    existing: updateDetailsForWorkspace(state.existing, {
      workspaceId: action.payload.workspaceId,
      isHidden: !action.payload.isHidden,
      isFullscreen: false, // Close workspace when hiding from web-suite due to breaking Dashboard header and layout
    }),
    selectedWorkspaceID: null,
  }))
  .handleAction(actions.toggleWorkspaceVisibility.failure, (state) => {
    return {
      ...state,
      activeOption: null,
      selectedWorkspaceID: null,
    };
  })
  .handleAction(actions.leaveWorkspace.request, (state, action) => {
    let existing = state.existing.filter(
      (workspace) => workspace.workspaceId !== action.payload.workspaceId
    );

    return {
      ...state,
      existing,
      activeOption: null,
      selectedWorkspaceID: null,
    };
  })
  .handleAction(actions.leaveWorkspace.failure, (state, action) => {
    const existing = [...state.existing];
    const insertPosition = action.payload.requestAction.payload.order || 0;
    existing.splice(insertPosition, 0, action.payload.requestAction.payload);

    return {
      ...state,
      existing,
      activeOption: null,
      selectedWorkspaceID: null,
    };
  })
  .handleAction([actions.deleteWorkspace.request], (state, action) => {
    let existing = state.existing.filter(
      (workspace) =>
        workspace.workspaceId !== action.payload.details.workspaceId
    );
    return {
      ...state,
      existing,
      activeOption: null,
      selectedWorkspaceID: null,
    };
  })
  .handleAction([actions.deleteWorkspace.failure], (state, action) => {
    const result = [...state.existing];
    const insertPosition =
      action.payload.requestAction.payload.details.order || 0;
    result.splice(
      insertPosition,
      0,
      action.payload.requestAction.payload.details
    );

    return {
      ...state,
      activeOption: null,
      existing: result,
      selectedWorkspaceID: null,
    };
  })
  .handleAction(actions.activateDashboardOption, (state, action) => {
    switch (action.payload.optionType) {
      case DashboardOption.SEARCH_WORKSPACE:
        return {
          ...state,
          activeOption: action.payload.optionType,
        };
      case DashboardOption.CREATE:
        return {
          ...state,
          activeOption: DashboardOption.CREATE,
          creationParameters: { useCaseName: action.payload.useCaseName! },
          defaultWorkspaceName: createWorkspaceName(state),
        };
      case DashboardOption.HIDE:
        return {
          ...state,
          activeOption: DashboardOption.HIDE,
          selectedWorkspaceID: action.payload.workspaceId!,
        };
      case DashboardOption.LEAVE:
        return {
          ...state,
          activeOption: DashboardOption.LEAVE,
          selectedWorkspaceID: action.payload.workspaceId!,
        };
      case DashboardOption.DELETE:
        return {
          ...state,
          activeOption: DashboardOption.DELETE,
          selectedWorkspaceID: action.payload.workspaceId!,
        };
      case DashboardOption.REMOVE:
        return {
          ...state,
          activeOption: DashboardOption.REMOVE,
          selectedWorkspaceID: action.payload.workspaceId!,
        };
      case DashboardOption.REMOVE_FROM_PROJECT:
        return {
          ...state,
          activeOption: DashboardOption.REMOVE_FROM_PROJECT,
          selectedWorkspaceID: action.payload.workspaceId!,
        };
      case DashboardOption.SHARE:
      case DashboardOption.INFO:
      case DashboardOption.PERMISSIONS:
        return {
          ...state,
          activeOption: action.payload.optionType,
          selectedWorkspaceID: action.payload.workspaceId!,
          emailPreset: action.payload.emailPreset,
          showOverlay: action.payload.showOverlay,
        };
      case DashboardOption.NOTIFICATION_CENTER:
        return {
          ...state,
          activeOption: DashboardOption.NOTIFICATION_CENTER,
          selectedWorkspaceID: action.payload.workspaceId!,
          dialogPosition: action.payload.dialogPosition
        }
      case DashboardOption.ADD_LABEL:
        return {
          ...state,
          activeOption: DashboardOption.ADD_LABEL,
          selectedWorkspaceID: action.payload.workspaceId!,
        };
      case DashboardOption.DUPLICATE:
        return {
          ...state,
          activeOption: DashboardOption.DUPLICATE,
          selectedWorkspaceID: action.payload.workspaceId!,
        };
      case DashboardOption.CREATE_FROM_TEMPLATE:
        if (!state.waitingToCreateFromTemplate) return state; // Don't activate the option if state for model is not set up
        return {
          ...state,
          activeOption: DashboardOption.CREATE_FROM_TEMPLATE,
        };
      case DashboardOption.ADVANCED_SHARE:
        return {
          ...state,
          activeOption: DashboardOption.ADVANCED_SHARE,
        };
      case DashboardOption.OPEN:
        return {
          ...state,
          activeOption: DashboardOption.OPEN,
        };
      case DashboardOption.OPTIONS:
      case DashboardOption.OPTIONS_FAVORITE:
        if (
          state.activeOption === action.payload.optionType &&
          state.selectedWorkspaceID === action.payload.workspaceId
        ) {
          return {
            ...state,
            activeOption: null,
            selectedWorkspaceID: null,
          };
        }
        return {
          ...state,
          activeOption: action.payload.optionType,
          selectedWorkspaceID: action.payload.workspaceId!,
        };
      case DashboardOption.INVITE:
        return {
          ...state,
          activeOption: DashboardOption.INVITE,
        };
      case DashboardOption.UPSELL:
        let title = action.payload.modalOptions?.modalTitle;
        let text = action.payload.modalOptions?.modalText;
        let buttonText = action.payload.modalOptions?.modalButtonText;
        let buttonLinkUrl = action.payload.modalOptions?.modalButtonLinkUrl;
        if (
          isElectronMac() &&
          buttonLinkUrl === "https://www.hoylu.com/pricing/"
        ) {
          title = Localized.string("MAC_APP.UPSELL_TITLE");
          text = Localized.string("MAC_APP.UPSELL_TEXT");
          buttonText = Localized.string("MAC_APP.UPSELL_BUTTON"); // button is hidden for MacApp
        }

        return {
          ...state,
          activeOption: DashboardOption.UPSELL,
          modalTitle: title,
          modalText: text,
          modalButtonText: buttonText,
          modalButtonLinkUrl: buttonLinkUrl,
          waitingToEditID: null,
          waitingToEditAllowTemplateEdit: undefined,
          isCreatingNewWorkspaceUrl: null,
        };
      case DashboardOption.ASSET_PANEL:
        return {
          ...state,
          activeOption: DashboardOption.ASSET_PANEL,
        };
      case DashboardOption.DOWNLOAD:
        return {
          ...state,
          activeOption: DashboardOption.DOWNLOAD,
          selectedWorkspaceID: action.payload.workspaceId!,
        };
      case DashboardOption.UPLOAD:
        return {
          ...state,
          activeOption: DashboardOption.UPLOAD,
        };
      case DashboardOption.MANAGE_ACCOUNT: {
        return { ...state, activeOption: DashboardOption.MANAGE_ACCOUNT };
      }
      case DashboardOption.CONFIRM_ACCOUNT_DELETE: {
        return {
          ...state,
          activeOption: DashboardOption.CONFIRM_ACCOUNT_DELETE,
        };
      }
      case DashboardOption.LINKED_MASTER:
      case DashboardOption.CHANGE_MASTER:
      case DashboardOption.DELETE_TEMPLATE: {
        return {
          ...state,
          activeOption: action.payload.optionType,
          selectedWorkspaceID: action.payload.workspaceId!,
        };
      }
      case DashboardOption.FILTER:
        return {
          ...state,
          activeOption: DashboardOption.FILTER,
        };
      default:
        return state;
    }
  })
  .handleAction(actions.workspaceProgressUpdate, (state, action) => {
    if (
      (state.loadProgress || 0) < action.payload.progress ||
      state.loadState !== action.payload.progressStep
    ) {
      return {
        ...state,
        loadProgress: action.payload.progress,
        loadState: action.payload.progressStep,
      };
    }
    return state;
  }) // todo
  .handleAction(actions.updateWorkspaceLabels.request, (state) => {
    return {
      ...state,
    };
  })
  .handleAction(actions.updateWorkspaceLabels.success, (state, action) => {
    return {
      ...state,
      existing: updateDetailsForWorkspace(state.existing, action.payload),
      selectedProjectWorkspaces: updateDetailsForWorkspace(
        state.selectedProjectWorkspaces,
        action.payload
      ),
    };
  })
  .handleAction(actions.updateWorkspaceLabels.failure, (state) => {
    const closeDialogOptions =
      state.activeOption === DashboardOption.ADD_LABEL
        ? {
            activeOption: null,
            selectedWorkspaceID: null,
          }
        : {};
    return {
      ...state,
      ...closeDialogOptions,
    };
  })
  .handleAction(actions.markWorkspaceAsFavorite, (state, action) => {
    const toggleFavoriteValue = (ws: WorkspaceDetails) =>
      ws.workspaceId === action.payload
        ? { ...ws, isFavorite: !ws.isFavorite }
        : ws;

    const existing = state.existing.map(toggleFavoriteValue);
    const selectedProjectWorkspaces = state.selectedProjectWorkspaces?.map(
      toggleFavoriteValue
    );

    return {
      ...state,
      existing,
      ...(selectedProjectWorkspaces && { selectedProjectWorkspaces }),
    };
  })
  .handleAction(actions.assignableProjects.success, (state, action) => ({
    ...state,
    assignableProjects: action.payload,
  }))
  .handleAction(actions.assignableProjects.failure, (state) => ({
    ...state,
    assignableProjects: undefined,
  }))
  .handleAction(actions.getMyTonnage.success, (state, action) => ({
    ...state,
    tonnageUsage: Math.round(action.payload.tonnagePercentageUsed),
  }))
  .handleAction(actions.getUserTonnage.success, (state) => ({
    ...state, //TODO: Where should we save different user tonnage?
  }))
  .handleAction(actions.recalculateUserTonnage.success, (state) => ({
    ...state, //TODO: Handle recalculation
  }))
  .handleAction(actions.getProjectsOfUser.success, (state, action) => ({
    ...state,
    projects: new Map(action.payload.map((p) => [p.id, p])),
  }))
  .handleAction(actions.addWorkspaceToProject.success, (state, action) => ({
    ...state,
    existing: updateDetailsForWorkspace(state.existing, {
      workspaceId: action.payload.workspaceId,
      containerId: action.payload.projectId,
    }),
    // no need to update selectedProjectWorkspaces because its not visible (and will be re-loaded)
  }))
  .handleAction(
    actions.removeWorkspaceFromProject.success,
    (state, action) => ({
      ...state,
      existing: updateDetailsForWorkspace(state.existing, {
        workspaceId: action.payload.workspaceId,
        containerId: undefined,
      }),
      // no need to update selectedProjectWorkspaces because it gets reloaded
    })
  )
  .handleAction(actions.getWorkspacesOfProject.request, (state) => ({
    ...state,
    isLoadingSelectedProjectWorkspaces: true,
  }))
  .handleAction(actions.getWorkspacesOfProject.success, (state, action) => ({
    ...state,
    selectedProjectWorkspaces: orderByLastAccess(action.payload),
    isLoadingSelectedProjectWorkspaces: false,
  }))
  .handleAction(actions.getWorkspacesOfProject.failure, (state) => ({
    ...state,
    isLoadingSelectedProjectWorkspaces: false,
  }))
  .handleAction(actions.editThumbnail, (state, action) => ({
    ...state,
    isEditingThumbnail: action.payload.flag,
  }))
  .handleAction(actions.setSearchTerm, (state, action) => ({
    ...state,
    searchTerm: action.payload,
  }))
  .handleAction(actions.uploadThumbnail.request, (state, action) => ({
    ...state,
    thumbnailsModified: state.thumbnailsModified.set(
      action.payload.workspaceId,
      null
    ),
    activeOption: null,
    isEditingThumbnail: false,
  }))
  .handleAction(actions.uploadThumbnail.success, (state, action) => ({
    ...state,
    thumbnailsModified: state.thumbnailsModified.set(
      action.payload.workspaceId,
      null
    ),
    activeOption: DashboardOption.INFO,
    isEditingThumbnail: false,
    selectedWorkspaceID: action.payload.workspaceId,
  }))
  .handleAction(actions.removeThumbnail.request, (state, action) => ({
    ...state,
    thumbnailsModified: state.thumbnailsModified.remove(
      action.payload.workspaceId
    ),
    activeOption: null,
    isEditingThumbnail: false,
  }))
  .handleAction(actions.removeThumbnail.success, (state, action) => ({
    ...state,
    thumbnailsModified: state.thumbnailsModified.remove(
      action.payload.workspaceId
    ),
    activeOption: DashboardOption.INFO,
    isEditingThumbnail: false,
    selectedWorkspaceID: action.payload.workspaceId,
  }))
  .handleAction(actions.workspaceSnapshot.request, (state) => ({
    ...state,
    activeOption: DashboardOption.REQUESTING_SNAPSHOT,
    isEditingThumbnail: false,
  }))
  .handleAction(actions.workspaceSnapshot.success, (state, action) => ({
    ...state,
    thumbnailsModified: state.thumbnailsModified.set(
      action.payload.workspaceId,
      action.payload.snapshot
    ),
    activeOption: DashboardOption.INFO,
    selectedWorkspaceID: action.payload.workspaceId,
    isEditingThumbnail: true,
  }))
  .handleAction(actions.workspaceSnapshot.failure, (state) => ({
    ...state,
    activeOption: DashboardOption.INFO,
    isEditingThumbnail: false,
  }))
  .handleAction(actions.selectProject, (state, action) => ({
    ...state,
    selectedProject: action.payload,
  }));
